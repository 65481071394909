<template>
  <div class="UserAuctionsView">
    <UserAuctions />
  </div>
</template>

<script>
import UserAuctions from '@/components/UserAuctions';
export default {
  name: 'UserAuctionsView',
  components: {
    UserAuctions
  }
};
</script>
